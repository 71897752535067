import { Box } from '@mui/material';
import React from 'react';
import Project from '../component/project';
import "../css/style.css"

const Projects = () => {
    return (
       <Box className="projects" sx={{paddingTop:"140px"}}>
        <title>مشاريعنا</title>
        <Project/>
       </Box>
    );
}

export default Projects;
