import { Box, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import Service from "../component/service";
import Footer from "../component/Footer";
import map from "../images/map.png";
import left from "../images/Group 34.png";

const Contact = () => {
  return (
    <Box className="contact" sx={{ paddingTop: "140px" }}>
      <title>تواصل معنا</title>
      <h1>ابقى على تواصل</h1>
      <Grid container className="container">
        <Grid item xs={12} md={6}>
          <p>
            مسؤوليتنا تجاه عملائنا هي الأولوية الأعلى و نحن نقوم بها بشكل ممتاز
            للحفاظ على أعلى المعايير ومستويات الأداء .إذا كان يوجد شيء تود
            معرفته لا تتردد في استخدام تفاصيل الاتصال لدينا المذكورة هنا، ونحن
            نضمن أن نعطيك الجواب الذي تريده
          </p>
        </Grid>
        <Grid item xs={11} md={6} sx={{ marginTop: { xs: "50px", md: "0px" } }}>
          <CardMedia component="img" image={left} />
        </Grid>
      </Grid>
      <Grid container className="form">
        <Grid item xs={10.5} md={6}>
          <h1 className="h1">تواصل معنا</h1>
          <form>
            <label>الاسم</label>
            <input type="text" />
            <label>البريد الالكتروني</label>
            <input type="text" />
            <label>الموضوع</label>
            <input type="text" />
            <label>الرسالة</label>
            <textarea></textarea>
            <input type="submit" className="sub" value="ارسال" />
          </form>
        </Grid>
      </Grid>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3626.215581567378!2d46.705991724783225!3d24.650706778066812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f04f7a43a81e3%3A0x2afb4f01a263e1da!2z2LTYp9ix2Lkg2KfZhNmI2LTZhdiMINin2YTYsdmK2KfYtiDYp9mE2LPYudmI2K_Zitip!5e0!3m2!1sar!2seg!4v1714050436414!5m2!1sar!2seg"
 
        style={{border:"0px", width :"90vw" , height : "70vh" , display :"block" , margin:"30px auto"}}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      />

      <Footer />
    </Box>
  );
};

export default Contact;
