import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import './styles.css';

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';
import img1 from "../images/projects/1.webp";
import img2 from "../images/projects/2.webp";
import img3 from "../images/projects/3.webp";
import img4 from "../images/projects/4.webp";
import img5 from "../images/projects/5.jpg";
import img6 from "../images/projects/6.webp";
import img7 from "../images/projects/7.webp";
import img8 from "../images/projects/8.webp";
import { Box } from '@mui/material';
const Slider = () => {
  // const responsive = {
  //     superLargeDesktop: {
  //       // the naming can be any, depends on you.
  //       breakpoint: { max: 1800, min: 1600 },
  //       items: 3,

  //     },
  //     desktop: {
  //       breakpoint: { max: 1600, min: 994 },
  //       items: 3,
  //     },
  //     tablet: {
  //       breakpoint: { max: 994, min: 350 },
  //       items: 2,
  //     },
  //     mobile: {
  //       breakpoint: { max: 350, min: 0 },
  //       items: 1,
  //     },
  //   };
  return (
 <Box className="owl" id="projects" >
  <h1 >مشاريعنا</h1>
    <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide className='slide'>
          <img src={img1} alt='...' />
        </SwiperSlide>
        <SwiperSlide className='slide'>
          <img src={img2} alt='...' />
        </SwiperSlide>
        <SwiperSlide className='slide'>
          <img src={img3} alt='...' />
        </SwiperSlide>
        <SwiperSlide className='slide'>
          <img src={img4} alt='...' />
        </SwiperSlide>
        <SwiperSlide className='slide'>
          <img src={img5} alt='...'/>
        </SwiperSlide>
        <SwiperSlide className='slide'>
          <img src={img6} alt='...' />
        </SwiperSlide>
        <SwiperSlide className='slide'>
          <img src={img7} alt='...' />
        </SwiperSlide>
        <SwiperSlide className='slide'>
          <img src={img8} alt='...' />
        </SwiperSlide>
        
      </Swiper>
 </Box>
  );
};

export default Slider;
