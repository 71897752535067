import { Box } from '@mui/material';
import React from 'react';

const Map = () => {
    return (
      <Box className="map">

        
        </Box>
    );
}

export default Map;
