import { Box, Grid, CardMedia } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import logo from "../images/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "./drawer";

const Nav = () => {
  let links = [
    { text: " الصفحة الرئيسية", path: "/" },
    { text: " من نحن", path: "/about-us" },
    { text: " خدماتنا", path: "/services" },
    { text: " مشاريعنا", path: "/#projects" },
    { text: " تواصل معنا", path: "/contact" },
    { text: " الهيكل الوظيفي", path: "/jobs" },
  ];
  let [top, setTop] = useState(false);
  useEffect(() => {
    window.onscroll = function () {
      if (window.location.pathname === "/") {
        if (window.scrollY > 650) {
          setTop(true);
        } else {
          setTop(false);
        }
      }
    };
  });
  let [drawer, setDrawer] = useState(false);
  return (
    <Box
      className="navbar"
      position="fixed"
      sx={{
        top: "0px",
        backgroundColor:
          window.location.pathname === "/" && top === false
            ? "transparent"
            : "white",
        zIndex: "1000",
        width: "100%",
        padding: { xs: "0px 30px", md: "0px" },
      }}
    >
      <Drawer drawer={drawer} setDrawer={setDrawer} />

      <Grid
        container
        className="container"
        sx={{ justifyContent: { xs: "space-between", md: "space-around" } }}
      >
        <Grid item>
          <MenuIcon
            className="menu"
            sx={{ display: { xs: "block", lg: "none" } }}
            onClick={() => {
              setDrawer(true);
            }}
          />
        </Grid>
        <Grid
          item
          className="links"
          xs={9}
          sx={{ display: { xs: "none", lg: "flex" } }}
        >
          {links.map((item) => {
            return (
              <a
                href={item.path}
                style={{
                  color:
                    window.location.pathname === "/"
                      ? top
                        ? "#333"
                        : "white"
                      : "#333",
                }}
              >
                {item.text}
              </a>
            );
          })}
        </Grid>
        <Grid item sx={{ display: { xs: "none", sm: "flex" } }}>
          <CardMedia
            component="img"
            image={logo}
            sx={{ width: "250px", height: "110px" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Nav;
