import { Box, Grid, CardMedia } from "@mui/material";
import React from "react";
import img1 from "../images/msg.png";
import img2 from "../images/phone.png";
import img3 from "../images/location.png";

import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import logo from "../images/logo.png";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const Footer = () => {
  return (
    <Box className="footer">
      {/* <CardMedia component="img" image={logo} className="logo"  sx={{width :"150px"}}/> */}

      <Grid container className="container">
       
        <Grid item xs={12} md={6} lg={3.5} className="address">
          <Box className="box">
            <CardMedia component="img" image={img1} />
            <p>info@dubco.net</p>
          </Box>
          <Box className="box">
            <CardMedia component="img" image={img2} className="img-2" />
            <p>
              <span> ت : 0112250915</span>
              <span>ه : 0559911374</span>
            </p>
          </Box>
          <Box className="box">
            <CardMedia component="img" image={img3} />

            <p>
              دبكو , مجمع المربع , الرياض مبنى 7074 / 2 الدور الخامس مكتب 88
            </p>
          </Box>
        </Grid>
        <Grid item xs={10} md={6} lg={4} className="contact">
          <p>ابقى على تواصل</p>
          <p>
            <b>تابعنا</b>
          </p>
          <Box className="icons">
           <FacebookIcon/>
           <WhatsAppIcon/>
           <InstagramIcon/>
           <XIcon/>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
