import { Box } from '@mui/material';
import React from 'react';
import Service from '../component/service';
import  Footer from "../component/Footer"


const Services = () => {
    return (
        <Box className="services" sx={{paddingTop:"140px"}}>
            <title>خدماتنا</title>
            <Service/>

            <Footer/>
            
        </Box>
    );
}

export default Services;
