import { Box, Grid, CardMedia, Button } from "@mui/material";
import React from "react";

// import Employer from "../component/employer";
import Index from "../component/index";
import Tasks from "../component/tasks";

import Footer from "../component/Footer";
import Slider from "../component/slider";

const Home = () => {
  return (
    <Box className="home index">
      <title>الصفحة الرئيسية</title>
      <Index />

      {/* <Employer /> */}
      <Tasks />

<Slider/>
      <Footer />
    </Box>
  );
};

export default Home;
